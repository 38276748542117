import * as React from "react"
import { Helmet } from "react-helmet"
import { Container, Button } from 'semantic-ui-react'
import HeaderH1 from '../components/header-h1'

const Deploy = (props) => {
  return (
    <Container style={{padding: 100}}>
      <Helmet>
        <meta name='robots' content='noindex, nofollow'/>
      </Helmet>
      <HeaderH1>Deploy new menus</HeaderH1>
      <p>Pushing this button will pull in all the latest dishes information and re-generate the menus for all restaurants.</p>
      <form action="https://api.netlify.com/build_hooks/619b832a87d83fe0940833f2" method="post">
          <Button name="Deploy" value="upvote">Deploy Menus</Button>
      </form>
    </Container>
  )
}

export default Deploy
